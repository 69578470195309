@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/mixins/breakpoints";

#content_container {
    min-height: 100vh;
    padding-top: rem($top-nav-height);
    padding-bottom: rem($top-nav-height);
    @include lg {
        padding-left: rem($side-nav-width);
        padding-top: rem($top-nav-height-lg);
    }
}
