@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.BannerBlock{
    margin-top:0px;

    .we.Image > span{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: rem(427);
        @include sm{
            height: rem(768);
        }
        @include md{
            height: rem(307);
        }
        @include lg{
            height: rem(326);
        }
        @include xl{
            height: rem(492);
        }
    }
}