@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.ResetSentBlock {
  .back {
    display: inline;
  }

  .icon {
    margin-top: rem(10);
    display: block;
    width: rem(62);
  }

  a {
    color: #19294f;
    text-decoration: underline;
  }

}