@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.Rating {
  .star {
    display: inline-block;
    height: rem(24);
    width: rem(24);
    margin: 0 rem(4);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    
    &.star-empty {
      background-image: url("../../../res/icons/star-empty.svg");
    }
    
    &.star-full {
      background-image: url("../../../res/icons/star-full.svg");
    }
  }

  &.large {
    .star {
      height: rem(32);
      width: rem(32);
      margin: 0 rem(6);
    }
  }
}