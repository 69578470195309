@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.RecordedCPDListBlock{
  .row {
    [class^='col-'], [class*=' col-'] {
      padding: 0;

      @include sm {  
        min-height: rem(315);
      }
      
      @include md {
        min-height: rem(117);
      }
      
      @include lg {
        min-height: rem(133.5);
      }
      
      @include xl {
        min-height: rem(184.8);
      }
    }
  }

  .Heading {
    margin-bottom: 0;
  }

  .Text {
    color: $navy-medium;
    margin-bottom: 0;
    @include lg {
      width: auto;
      font-size: rem(16);
    }
  }

  .VideoTile {
    text-align: left;
    text-transform: none;
    width: rem(304);
    height: 100% !important;

    @include sm {  
      width: rem(560);
    }
    
    @include md {
      width: rem(208);
    }
    
    @include lg {
      width: rem(237);
    }
    
    @include xl {
      width: rem(328);
    }

    &:active,
    &:hover {
      text-decoration: none;
    }
    
    &:nth-of-type(3n+1) {
      padding-left: 0;
      padding-top: rem(16);
    }

    .tile-content {
      position: relative;
      padding: 0;

      .we.Image {
        background: #d8d8d8;
        height: rem(171);
        width: 100%;

        .desktop {
          flex-direction: column;
        }

        .mobile {
          flex-direction: column;
        }

        @include sm {  
          height: rem(315);
        }
        
        @include md {
          height: rem(117);
        }
        
        @include lg {
          height: rem(133.5);
        }
        
        @include xl {
          height: rem(184.8);
        }

        .playButton-icon {
          margin-top: rem(65);

          @include sm {
            margin-top: rem(137);
          }
          
          @include md {
            margin-top: rem(43);
          }
          
          @include lg {
            margin-top: rem(51);
          }
          
          @include xl {
            margin-top: rem(76);
          }
        }
      }

      .we.Tag {
        margin-top: rem(33);
        margin-left: rem(256);

        @include sm {  
          margin-top: rem(105);
          margin-left: rem(512);
        }
        
        @include md {
          margin-top: rem(11);
          margin-left: rem(160);
        }
        
        @include lg {
          margin-top: rem(19);
          margin-left: rem(189);
        }
        
        @include xl {
          margin-top: rem(44);
          margin-left: rem(279);
        }
      }

      .video-title {
        padding: rem(16);

        @include lg {
          font-size: rem(20);
          letter-spacing: rem(0.21);
        }
      }

    }
  }
}