@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.Modal{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;

  &.modal-dialog {
    max-width: inherit;
  }

  .modal-content {
    background: transparent;
    height: 100%;
    border: none;

    .modal-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .modal-close {
      position: absolute;
      top: rem(16);
      right: rem(16);
      height: rem(24);
      width: rem(24);
      background: url("../../../res/icons/close-white.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      padding: 0;
      border: none;
  
      @include sm {
        top: rem(24);
        right: rem(24);
      }
  
      @include md {
        top: rem(64);
        right: rem(64);
        height: rem(40);
        width: rem(40);
      }
  
      img {
        height: 100%;
        width: 100%;
      }
  
    }
  }
}

.modal-overlay{
  width: 100vw;
  height: 100vh;

  &.show {
    opacity: 0.9;
  }
}