@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FormBlockForm {
  .ErrorMessage {
    height: 100%;
  }

  .loading-view {
    .Spinner {
      padding: rem(154) 0;
    }
  }
}