@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.LiveWebinarBlock .row{
    background-color: $white;

    .we.Heading{
        margin-top: rem(8);
        margin-bottom: 0;
    }

    .image-container{
        height: rem(208);
        @include xs-sm{
            height: rem(259);
        }
        @include sm{
            height: rem(373);
        }
        @include md{
            height:rem(200);
        }
        @include lg{
            height:rem(225);
        }
        @include xl{
            height:rem(312);
        }
    }

    .content-container{
        &[class*=' col-']{
            @include lt-md{
                padding:rem(16);
            }
            @include md{
                padding-right:rem(16);
            }
            @include xl{
                padding-right:rem(32);
            }
        }
    }

    .we.Tag{
        margin-top: rem(8);
        @include md{
            margin-top: rem(16);
        }
        @include lg{
            margin-top: rem(24);
        }
        @include xl{
            margin-top: rem(32);
        }
    }

    .we.Button{
        width:100%;
        vertical-align: bottom;
        &:not(:last-child){
            margin-bottom: rem(16);
        }
        @include md{
            width: rem(202);
            &:not(:last-child){
                margin-right: rem(16);
                margin-bottom: 0;
            }
        }
    }
}