@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.SearchWidget {
    display: none;
    &.open {
        display: flex;
        //Layer the widget on top of all items in the nav
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
    }
    background-color: $navy-high;

    @include lg {
        display: flex;
        position: static;
        margin-left: rem(115);
        width: rem(312);
        background-color: $white;
        margin-right: auto;
        &.open {
            position: static;
            width: rem(312);
        }
    }
}
