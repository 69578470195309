@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.AccountWidget {
    height: 100%;

    .title-container {
        display: inline-block;
        font-family: Soleil;
        font-weight: 600;
        font-size: rem(14);
        letter-spacing: rem(1.8);
        color: $white;

        &:hover {
            text-decoration: underline;
        }
        .profile-image {
            margin-right: rem(8);
        }
        .profile-username {
            margin-right: rem(8);
            display: none;
            @include md {
                display: inline-block;
            }
        }
        .caret-down {
            height: rem(5);
        }
    }
    .dropdown-toggle {
        height: 100%;
        display: flex;
        align-items: center;
        &:hover,
        &:active,
        &:focus {
            background-color: rgba(255, 255, 255, 0.25);
        }
        //Remove Caret created by bootstrap styles
        &:after {
            border: none;
        }
    }
    .dropdown-menu {
        left: auto;
        right: 0;
        font-family: Soleil;
        font-size: rem(14);
        letter-spacing: rem(1);
        color: $primary;
        border-radius: 2px;
        margin: 0;
        width: rem(208);
    }
}
