/*
* File: Colors
* Defines colors used throughout the app
*/

//Brand colors
$primary: #19294f;

//Greys
$grey-low: #616161;
$grey-medium: #424242;
$grey-high: #202020;

//Browns
$brownish-grey: #757575;

//Whites
$white-low: #eeeeee;
$white-medium-lite: #e0e0e0;
$white-semi-low: #ebebeb;
$white-medium: #f5f5f5;
$white-high: #fefefe;
$white: #ffffff;

//navys
$navy-low: #57637f;
$navy-medium: #424f6f;
$navy-high: #19294f;

//Blues
$blue-bright: #037aff;

//Notification
$danger: #e41647;
$success: #0d7f50;
