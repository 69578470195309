@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

//WE Button Styles
.we.Button {
    font-family: Soleil;
    line-height: rem(16);
    border-radius: rem(1);
    font-size: rem(14);
    letter-spacing: rem(0.9);
    text-align: center;
    text-transform: uppercase;
    padding: rem(15) rem(24);
    &:active,
    &:hover {
        text-decoration: underline;
    }

    //Light Theme
    &.light {
        border: solid rem(1) $navy-high;
        //Primary Variant
        &.primary {
            background-color: $navy-high;
            color: $white;
            //Disable buttons
            &:disabled,
            //Disable Links
            &.disabled {
                border: solid rem(1) $brownish-grey;
                background-color: $brownish-grey;
                text-decoration: none;
            }
            &:hover {
                opacity: 0.8;
            }
            &:focus {
                opacity: 0.8;
            }
            &:active {
                opacity: 0.8;
                background-color: $navy-high;
            }
        }

        //Secondary Variant
        &.secondary {
            color: $navy-high;
            background-color: $white;
            //Disable buttons
            &:disabled,
            //Disable Links
            &.disabled {
                color: $grey-low;

                text-decoration: none;
            }
            &:hover {
                background-color: $white-low;
            }
            &:focus {
                color: $navy-high;
                background-color: $white-low;
                border: solid rem(1) $grey-medium;
            }
        }

        // Tertiary variant
        &.tertiary {
            color: $navy-high;
            background-color: $white;
            border: solid rem(1) $white;
        }

        // Text variant
        &.text {
            letter-spacing: rem(1);
            height: auto;
            padding: 0;
            background-color: transparent;
            border: none;
            color: $navy-high;
        }
    }

    //TODO: Implement Secondary Variant
    //Dark Theme
    &.dark {
        //Primary Variant
        &.primary {
            background-color: transparent;
            border: solid rem(1) $white;
            color: $white;
            //Disable buttons
            &:disabled,
            //Disable Links
            &.disabled {
                background-color: $brownish-grey;
                text-decoration: none;
            }
        }
        &.text {
            font-size: rem(14);
            line-height: rem(16);
            letter-spacing: rem(1);
            text-align: center;
            height: auto;
            padding: 0;
            background-color: transparent;
            border: none;
            color: $white;
        }
    }

    &.google {
        background: #FEFEFE;
        color: rgba(0, 0, 0, 0.54);
        padding: rem(12);
        border-style: hidden;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }

    &.facebook {
        background: #1877F2;
        padding: rem(12);
        border-style: hidden;
    }

    &.apple {
        background: #000000;
        padding: rem(12);
        border-style: hidden;
    }
    &.google, &.facebook, &.apple{
        img {
            margin-right: rem(16);
        }
    }

    img {
        height: 100%;
    }
}

