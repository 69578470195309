@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.UpcomingWebinarListBlock{
  .row {
    [class^='col-'], [class*=' col-'] {
      padding: 0;

      @include sm {  
        min-height: rem(315);
      }
      
      @include md {
        min-height: rem(117);
      }
      
      @include lg {
        min-height: rem(133.5);
      }
      
      @include xl {
        min-height: rem(184.8);
      }
    }
  }

  .WebinarTile {
    text-align: left;
    text-transform: none;
    width: rem(304);
    height: 100% !important;

    @include sm {  
      width: rem(560);
    }
    
    @include md {
      width: rem(208);
    }
    
    @include lg {
      width: rem(237);
    }
    
    @include xl {
      width: rem(328);
    }

    &:active,
    &:hover {
      text-decoration: none;
    }
    
    &:nth-of-type(3n+1) {
      padding-left: 0;
      padding-top: rem(16);
    }

    .tile-content {
      position: relative;
      padding: 0;

      .we.Image {
        background: #d8d8d8;
        height: rem(171);
        width: 100%;

        @include sm {  
          height: rem(315);
        }
        
        @include md {
          height: rem(117);
        }
        
        @include lg {
          height: rem(133.5);
        }
        
        @include xl {
          height: rem(184.8);
        }
      }

      .we.Tag {
        margin-top: rem(147);
        margin-left: rem(190);

        @include sm {  
          margin-top: rem(291);
          margin-left: rem(446);
        }
        
        @include md {
          margin-top: rem(93);
          margin-left: rem(94);
        }
        
        @include lg {
          margin-top: rem(109);
          margin-left: rem(123);
        }
        
        @include xl {
          margin-top: rem(161);
          margin-left: rem(214);
        }
      }

      .webinar-title {
        padding: rem(16);

        @include lg {
          font-size: rem(20);
          letter-spacing: rem(0.21);
        }
      }

    }
  }
}