@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.VideoModalPlayer {
  width: 100vw;

  @include lg {
    width: rem(784);
  }

  @include xl {
    width: rem(1039);
  }
}