@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.RatingFormModal {
  $modal-min-y-margin-px: 28;

  display: flex;
  flex-direction: column;
  margin: auto;
  width: rem(304);
  padding: rem(40) rem(16);
  border-radius: rem(8);
  background-color: $white;
  max-height: calc(100vh - #{rem($modal-min-y-margin-px * 2)});

  @include sm {
    width: rem(560);
    padding: rem(40) rem(24);
  }

  @include md {
    width: rem(656);
    padding-bottom: rem(24);
  }

  @include lg {
    width: rem(744);
    padding: rem(48);
  }

  .close-button {
    background: url("../../../../../res/icons/close-grey.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: rem(24);
    width: rem(24);
    padding: 0;
    border: none;
    position: absolute;
    top: rem(16);
    right: rem(16);
  }

  .form-modal-body {
    overflow-y: auto;
  }

  .ErrorMessage {
    height: 100%;
  }

  .loading-view {
    .Spinner {
      padding: rem(154) 0;
    }
  }
}