@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.ForgotPasswordBlock {
  padding: rem(24) rem(16);
  @media (min-width: 576px) {
    padding: rem(32);
  }
  .input {
    width: 100%;
  }
  
  .button{
    margin-top: rem(24);
    width: 100%;
    text-decoration: none;
  }

  .label {
    margin-top: rem(24);
  }

}