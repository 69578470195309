@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.auth-page {
    background-color: $white-medium;
    background-size: cover;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    .block-container {
        display: flex;
        overflow: auto;
        flex-direction: column;
        width: 100%;
        max-width: unset;
        padding: rem(32) 0;
        margin-top: rem(48);
        @include lg {
            margin-top: rem(56);
        }
    }
    
    .Block {
        margin-top: rem(32);
        align-self: center;
        background: white;
        width: rem(304);

        @include sm{
            width: rem(480);
            padding: rem(32) rem(32);
        }
    }
}