@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.nav-link {
    font-family: Soleil;
    font-size: rem(14);
    line-height: rem(16);
    letter-spacing: rem(0.5);
    color: $primary;
    outline-color: $blue-bright;
    &.active {
        background-color: $white-semi-low;
    }
    &:active {
        background-color: $white-medium-lite;
        color: $primary;
    }
    &:hover {
        background-color: $white-semi-low;
        text-decoration: underline;
    }
}