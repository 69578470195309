@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.CompletionModal {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 502px;
  @include sm {
    margin-left: 8px;
    margin-right: 8px;
  }

  @include md {
    margin-left: 56px;
    margin-right: 56px;
  }
  
  @include lg {
    width:744px;
  }

}

.we.Text.completionContent {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var(--navy-low);
  @include sm {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include md {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include lg {
    font-size: 16px;
    padding-left: 72px;
    padding-right: 72px;
  }
}

.we.Text.completionTitle {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
  @include lg {
    font-size: 32px;
  }
}

.we.Image.completionImage {
  width: 368px;
  height: 245px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.we.Button.completeButton {
  height: 48px;
  margin: 16px 16px 24px 16px;
  @include md {
    width: 216px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
}