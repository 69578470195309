@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.VideoPlayer{
  .player-loading {
    padding: 0;
    color: $white-low;
  }

  .player-error {
    text-align: center;

    > * {
      margin-bottom: rem(8);
      color: $white-high;

      @include lg {
        margin-bottom: 0;
      }
    }
  }

  .player-hidden {
    display: none;
  }
}