@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Heading{
    font-family: Soleil;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;

    //Themes
    &.light{
        color: $navy-high;
    }
    &.dark{
        color: $white;
    }

    //Variations
    &.page  {
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: rem(0.25);
        @include lg{
            font-size: rem(32);
            line-height: rem(48);
            letter-spacing: rem(0.5);
        } 
    }
    &.block{
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: rem(0.25);
        margin-bottom: rem(16);
        @include lg{
            font-size: rem(32);
            line-height: rem(48);
            letter-spacing: rem(0.5);
            margin-bottom: rem(24);
        }
    }
    &.card{
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(0.1);
        @include lg{
            font-size: rem(24);
            line-height: rem(32);
            letter-spacing: rem(0.25);
        }
    }
}
