@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.SearchForm {
    display: flex;
    flex-grow: 1;
    @include lg {
        background-color: $white;
        padding: rem(1);
    }

    .we.Input {
        padding: rem(12) rem(12) rem(12) 0;
        width: 100%;
        margin: 0;
        background-color: $white;
        @include lg {
            padding: rem(8);
        }
        input {
            border: none;
            //Increased font to 16px to avoid iOS autozoom
            font-size: rem(16);
            height: rem(24);
            line-height: rem(24);
            padding: 0;
            @include lg {
                height: rem(24);
            }
        }

        &:focus,
        &:active {
            //Disable boostrap and default blue outline
            outline: none !important;
            box-shadow: none;
        }
    }
}
