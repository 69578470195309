@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.sign-in-block {
    background-color: #fff;
    margin: rem(32) auto rem(16);
    width: rem(480);
    padding: rem(24) rem(16);

    @media (min-width: 576px) {
        padding: rem(32);
    }

    .Text {
        margin: 0px;
    }

    img.icon {
        height: rem(24);
        width: rem(24);
        margin: rem(11);
        @include lg {
            margin: rem(7);
        }
    }

    .Button{
        width: 100%;
        margin-top: rem(12);
        margin-bottom: rem(12);
        height: rem(48);
    }

    .Input{
        width: 100%;
        label{
            margin-top: rem(16);
            font-size: rem(14);
        }
    }

    a {
        margin-top: rem(16);
        font-size: rem(14);
    }

    .forgot-button {
        text-align: left;
    }

    .button-group {
        text-align: center;

        .Text {
            margin-top: rem(16);
            margin-bottom: rem(16);
            font-size: rem(14);
        }

        .Button {
            width: 100%;
            margin-top: rem(8);
            margin-bottom: rem(8);
        }
    }

    .error-state{
        img{
            margin: auto rem(8) auto 0;
            vertical-align: middle;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: rem(16);

        position: static;
        width: rem(416);
        left: calc(50% - 416px/2);
        top: 14.61%;
        bottom: 77.53%;

        background: #FEFEFE;
        border: 2px solid #E41647;
        border-radius: 4px;

        /* Inside Auto Layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 24px 0px;
    }
}

.we.cta-block {
    background-color: #fff;
    margin: 0 !important;
    width: rem(480);
    padding: rem(24) rem(16);
    text-align: center;

    @media (min-width: 576px) {
        padding: rem(32);
    }

    .Heading {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.25px;
        margin-bottom: 0;

        @include lg {
            font-size: rem(24);
            line-height: rem(32);
        }
    }

    .Button{
        width: 100%;
        margin-top: rem(16);
        @media (min-width: 576px) {
            width: rem(272);
        }
    }
}