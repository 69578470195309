@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FormStackForm {
  font-family: Soleil;

  .field {
    margin-bottom: rem(20);
  }

  .Label {
    margin-bottom: rem(10);
  }
  
  .supporting-label {
    font-size: rem(12);
    color: #616161;
  }

  .word-count {
    font-size: rem(12);
    color: #19294F;
    margin-bottom: rem(0);
  }

  &.rating-form {
    .Heading {
      text-align: center;
    }

    .submit-button-wrapper {
      text-align: center;

      .submit-button {
        width: 100%;

        @include lg {
          width: rem(202);
        }
      }
    }

    .fields {
      border-bottom: solid rem(1) $white-low;
      padding-top: rem(8);
      padding-bottom: rem(8);
      margin-bottom: rem(10);

      @include lg {
        margin-bottom: rem(18);
      }
    }
  }
  
  .rating-form-rating-field {
    text-align: center;
    border-bottom: solid rem(1) $white-low;
    padding-bottom: rem(24);
    margin-bottom: rem(24);

    .Label {
      color: $navy-low;
      margin-bottom: rem(16);
    }


    @include lg {
      padding-bottom: rem(32);
      
      .Label {
        margin-bottom: rem(32);
      }
    }
  }

  .radio-field, .checkbox-field {
    .Label {
      color: $navy-high;
    }

    .option {
      display: flex;
      align-items: center;
      padding-left: rem(8);
      margin-bottom:rem(6);

      input {
        position: static;
        margin: unset;
        width: rem(14);
        height: rem(14);
        border: solid rem(1) $navy-high;
        background-color: $white-high;
      }

      label {
        margin-left: rem(8);
        font-size: rem(14);
        line-height: rem(20);
        color: $navy-medium;
      }
    }
  }

  .checkbox-field {
    .option {
      input {
        border-radius: rem(3);
      }
    }
  }

  .richtext-field {
    color: $navy-high;
  }

  .submit-button {
    margin-top: rem(14);
    width: 100%;


    @include md {
      width: rem(128);
    }

    @include lg {
      width: rem(107);
    }
  }
}