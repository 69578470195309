@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.CreatePasswordBlock {

  .description {
    margin-bottom: rem(16);
    color: #424F6F;
  }

  .input {
    width: 100%;
    
    .confirm{
      border-color: $danger;
    }
  }
  
  .button{
    margin-top: rem(24);
    width: 100%;
    text-decoration: none;
  }

  .error {
    color: $danger;
  }

}