@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.completeProfileBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: rem(24) rem(16);
    border: none;
    border-radius: 0px;
    background-color: $white;

    button.confirmButton {
      width: 100%;
    }
  
    .headingContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .blockHeading {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > div.Input:not(:nth-last-child(2)) {
        margin-bottom: 1rem;
      };
      > div.Input:nth-last-child(2) {
        margin-bottom: rem(24);
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .Input {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        width:100%;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  }
  
  @media (min-width: 576px) {
    .completeProfileBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: rem(32);
      border: none;

    
      .blockHeading {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        @media (max-width: 767px) {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    
      .form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // > * {
        //   margin-top: 1rem;
        // }
        align-self: stretch;
        flex: 0 0 auto;
        @media (max-width: 767px) {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    
      .Input {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        > * {
          margin-top: 0.25rem;
        }
        align-self: stretch;
        flex: 0 0 auto;
        @media (max-width: 767px) {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    
    }
  }
  
  @media (min-width: 768px) {
    .completeProfileBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 480px;
      border: none;

      .blockHeading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    
      .form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // > * {
        //   margin-top: 1rem;
        // }
        align-self: stretch;
        flex: 0 0 auto;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    
      .Input {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        > * {
          margin-top: 0.25rem;
          width:100%;
        }
        align-self: stretch;
        flex: 0 0 auto;
        &:nth-child(1) {
          margin-top: 0;
        }
        > .Control {
          width: 100%;
        }
      }
    }
  }