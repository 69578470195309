@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.ContentBlurbBlock{
    text-align: center;
    img{
        margin: rem(8) auto;
        max-height: rem(53);
        @include lg{
            margin: rem(16) auto;
            max-height: rem(75);
        }
        @include xl{
            max-height: rem(93);
        }
    }
    .we.Card .Content{

        @include md{
            padding:rem(24);
        }
        @include xl{
            padding:rem(32);
        }
    }

}