@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Card{
    background-color:$white;
    margin-bottom:rem(16);
    @include md{
        height: 100%;
    }
    
    .Content{
        padding: rem(16);
        @include lg{
            padding:rem(24);
        }
    }
    .Header{
        height: rem(208);
        @include xs-sm {
            height: rem(266);
        }
        @include md{
            height: rem(213);
        }
        @include lg{
            height: rem(242);
        }
        @include xl{
            height: rem(335);
        }
        
    }
}