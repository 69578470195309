@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FormBlockSubmitted {
  display: flex;
  flex-direction: column;
  align-items: center;

  .submitted-image {
    width: rem(272);
    height: rem(108);
    margin-bottom: rem(16);

    @include lg {
      width: rem(438);
      height: rem(174);
      margin-bottom: rem(32);
    }
  }

  .header {
    margin-bottom: rem(4);

    @include lg {
      margin-bottom: rem(8);
    }
  }

  .body-text {
    color: $navy-low;
    margin-bottom: rem(24);
    
    @include lg {
      margin-bottom: rem(40);
    }
  }

  .dismiss-button {
    width: rem(154);

    @include lg {
      width: rem(202);
    }
  }
}