@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.course-container {
  background-color: $white-medium;
  min-height: calc( 100vh - #{rem($top-nav-height)} );
  
  @include lg {
    min-height: calc( 100vh - #{rem($top-nav-height-lg)} );
  }

  .module-container {
    margin: 0;
    width: 100%;
    max-width: unset;
    padding: rem(48) rem(8);

    @include md {
      padding: rem(48) rem(56);
    }

    @include lg {
      padding: rem(48) rem(141);
    }

    @include xl {
      padding: rem(48) rem(204);
    }
  }
}
