@import "src/res/scss/colors";

.we.RadioOption {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .RadioOption__Indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    border: 1px solid $navy-high;
    margin-right: 12px;
  }
  
  .Selected {
    justify-content: center;
    align-items: center;
    background-color: $navy-high;

    &:after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: $white;
      border-radius: 50%;
    }
  }

  .RadioLabel {
    margin: 0;
  }
}
