@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.Quiz {
  padding: 24px;
};

.we.Text.Question_Number {
  font-size: 14px;
  color: $navy-low;
};

.we.Heading.Question_Header {
  margin-bottom: 16px;

  @include lg {
    margin-bottom: 24px;
  }
};

.we.Quiz_Option {
  border-radius: 4px;
  background-color: $white-medium;
  padding: 24px 16px;
  margin-bottom: 16px;
  border: solid 2px transparent;
};

.we.Highlight {
  border: solid 2px $navy-high;
};

.we.Row {
  display: flex;
  flex-wrap: wrap;

  button {
    width: 100%;

    &:first-of-type {
      margin-bottom: 16px;
    }
  }

  @include md {
    flex-wrap: nowrap;
    justify-content: space-between;
    
    button {
      width: auto;

      &:first-of-type {
        margin-bottom: 0;
        min-width: 128px;
      }

      &:nth-of-type(2) {
        width: rem(110);
      }

      &:last-of-type {
        justify-self: flex-end;
      }
    }
  }
};

.we.AnswerExplanation {
  margin-bottom: 16px;
}
