@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.LocationCaptureForm{
  margin-bottom: 1rem;

  .checkbox {
    margin-top: 0.25rem;
    float: left;
  }

  .checkbox-label {
    overflow: hidden;
    margin: rem(26) 0 rem(26) 0;
  }

  &.Button {
    width: 100%;
    @include sm{
      width: rem(140);
    }
  }
}