@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.form-submitted-block {
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: rem(304);
    left: rem(480);
    top: rem(88);
    margin: auto;
    padding: rem(24) rem(16);
    @media (min-width: 576px) {
        width: rem(560) !important;
        padding: rem(32);
    }
    @media (min-width: 768px) {
        width: rem(656) !important;
    }
    @media (min-width: 1024px) {
        width: rem(741) !important;
    }
    @media (min-width: 1440) {
        width: rem(1032) !important;
    }
    

    .Image {
        width: rem(160);
        height: rem(140);
        display: block;
        margin: 0 auto;
    }

    .Heading {
        /* 🖥️/Page title */
        font-family: Soleil;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;

        /* identical to box height, or 150% */
        text-align: center;
        letter-spacing: 0.5px;

        /* Navy/High */
        color: #19294F;

        /* Inside Auto Layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 12px 0px;

        @include lg {
            font-size: 32px;
            line-height: 48px;
        }
    }

    .Text {
        /* 🖥️/Body */
        font-family: Soleil;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */
        text-align: center;

        /* Navy/High */
        color: #19294F;

        /* Inside Auto Layout */
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: 12px 0px;
    }
}

.we.form-cta-block {
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: rem(304);
    left: rem(480);
    top: rem(88);
    margin: auto;
    padding: rem(24) rem(16);
    @media (min-width: 576px) {
        width: rem(560) !important;
        padding: rem(32);
    }
    @media (min-width: 768px) {
        width: rem(656) !important;
    }
    @media (min-width: 1024px) {
        width: rem(741) !important;
    }
    @media (min-width: 1440) {
        width: rem(1032) !important;
    }

    .Button {
        background: #19294F;
        margin: 0 auto;
        width: rem(272);
        height: rem(48);
        display: block;
    }
}