@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FormBlock {
  .dismiss-button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: rem(24);

    button {
      background: url("../../../../res/icons/close-grey.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: rem(18);
      width: rem(18);
      padding: 0;
      border: none;
    }
  }

  .block-image {
    width: rem(272);
    height: rem(108);
    display: block;
    margin: auto;
  }

  .content {
    text-align: center;
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include sm {
      margin-right: rem(8);
      margin-left: rem(8);
    }

    @include md {
      margin-right: rem(40);
      margin-left: rem(40);
    }

    @include lg {
      margin-top: rem(24);
    }

    @include lg {
      margin-right: rem(152);
      margin-left: rem(152);
    }

    .we.Text {
      color: $navy-low;
    }
  }
}