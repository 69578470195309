@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.ExplanationContainer {
  padding: 24px 16px;
  border-radius: 4px;

  p, h3 {
    margin: 0;
  }

  @include lg { 
    h3.Heading {
      font-size: 16px;
    }
  }

  &.ExplanationError {
    border: 2px solid $danger;
    padding: rem(16);
  }

  &.ExplanationCorrect {
    border: 2px solid $success;
  }
  
  &.ExplanationIncorrect {
    border: 2px solid $danger;
  }
  &.ExplanationError {
    border: 2px solid $danger;
    padding: rem(16);

    h3 {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .Explanation {
    margin-top: 16px;
  }
};

.we.ExplanationHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
  }
}



