@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.ImageBlock{
    .we.Image{
        height: rem(171);
        @include xs-sm{
            height:rem(220);
        }
        @include sm{
            height: rem(315);
        }
        @include md{
            height: rem(374);
        }
        @include lg{
            height: rem(418);
        }
        @include xl{
            height: rem(581);
        }
    }


}