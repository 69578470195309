@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Tag{
    width: fit-content;
    height: fit-content;
    font-family: Soleil;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-size: rem(10);
    letter-spacing: rem(1);
    line-height: rem(10);
    
    text-align: center;
    text-transform: uppercase;

    //Themes
    &.light{
        &.contained{
            background-color: $navy-high;
            color: $white;
        }
        &.outline{
            border: solid 1px $navy-high;
            color: $navy-high;
        }
    }
    &.dark{
        &.contained{
            background-color: $white;
            color: $navy-high;
        }
        &.outline{
            border: solid 1px $white;
            color: $white;
        }
    }
    &.danger{
        &.contained{
            background-color: $danger;
            color: $white;
        }
        &.outline{
            border: solid 1px $danger;
            color: $danger;
        }
    }

    &.small{
        padding: rem(3) rem(8);
    }
    &.big{
        padding: rem(5) rem(8);
    }
}