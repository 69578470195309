@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Image{
    display:inline-block;
    height: 100%;
    width: 100%;
    > span{
        height: 100%;
        width: 100%;
        position: relative; 
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &.mobile{
            display:flex;
            @include md{
                display:none;
            }
        }
        &.desktop{
            display:none;
            @include md{
                display:flex;
            }
        }
    }

}