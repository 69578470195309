@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FeaturedCollectionsBlock{
  margin: rem(24) 0;

  .FeaturedCollectionsTile {
    &:not(:last-child){
      margin-bottom: rem(16);

      @include md {
        margin-bottom: 0;
      }
    }


    .block-image {
      background-color: $navy-high;
      height: rem(121.6);
      width: rem(304);
  
      @include sm {  
        height: rem(225);
        width: rem(560);
      }
  
      @include md {
        height: rem(128.7);
        width: rem(320);
      }
  
      @include lg {
        height: rem(146);
        width: rem(363);
      }
  
      @include xl {
        height: rem(202.6);
        width: rem(504);
      }


      .tile-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        padding: rem(16);

        .tile-footer {
          .tile-action {
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}