@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.VideoCoursePlayer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  .VideoPlayer {
    min-height: rem(171);
  }

  .we.Row {
    display: flex;
    flex-wrap: wrap;
    padding: rem(16);
  
    @include md {
      padding: rem(24);
    }
  
    button {
      width: 100%;
  
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  
    @include md {
      flex-wrap: nowrap;
      justify-content: space-between;
      
      button {
        width: auto;
  
        &:first-of-type {
          margin-bottom: 0;
          min-width: 128px;
        }
  
        &:nth-of-type(2) {
          width: rem(110);
        }
  
        &:last-of-type {
          justify-self: flex-end;
        }
      }
    }
  };  
}
