@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.FeaturedCollectionBlock {
  margin: rem(24) 0;

  .block-image {
    background-color: $navy-high;
    width: rem(304);
    height: rem(405);

    @include sm {  
      width: rem(560);
      height: rem(746);
    }

    @include md {
      width: rem(656);
      height: rem(262);
    }

    @include lg {
      width: rem(743);
      height: rem(296.7);
    }

    @include xl {
      width: rem(1032);
      height: rem(412);
    }

    .tile-content {
      margin: rem(16);

      @include md{
        max-width: rem(360);
      }

      @include lg{
        max-width: rem(474);
      }

      .tile-title {
        margin-bottom: rem(8);

        @include md{
          max-width: rem(360);
        }
        @include lg{
          max-width: rem(474);
        }
      }

      .tile-action {
        display: flex;
        align-items: center;
      }
    }
  }
}