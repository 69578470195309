@import "bootstrap/scss/bootstrap.scss";
@import "./res/scss/fonts";
@import "./res/scss/colors";

//Bootstrap reset
//TODO: only include used bootstrap scss(Nav and Grid)
.card{
    position: static;
    display: block;
    word-wrap: normal;
    background-color: transparent;
    background-clip: border-box;
    border: none;
    border-radius: 0;
}

#content_container {
    background-color: $white-medium;
}
