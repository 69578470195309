/*
* File: Breakpoints
* Utility for creating media queries that align with the defined breakpoints
*/

// Bootstraps breakpoints.
//TODO: pull these values from bootstraps _variables file
$screen-xs-min: 0;
$screen-xs-sm-min: 425px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Extra Small devices
@mixin xs-sm {
    @media (min-width: #{$screen-xs-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}


// Medium devices
@mixin lt-md {
    @media (max-width: #{$screen-md-min - 1}) {
        @content;
    }
}
@mixin lt-lg {
    @media (max-width: #{$screen-lg-min - 1}) {
        @content;
    }
}