@import "src/res/scss/variables";
@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.form-page {
  background-color: $white-medium;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .subheader {
    top: rem(48);
    position: sticky;
    z-index: 1;

    @include lg {
      top: rem(56);
    }
  }

  .form-container {
    background-color: $white-high;
    margin: rem(24) auto;
    width: rem(304);
    padding: rem(16) rem(8);

    textarea{
      resize: none;
    }

    @include sm {
      padding: rem(24) rem(8);
      width: rem(560);
    }

    @include md {
      padding: rem(24);
      width: rem(656);
      margin: rem(32) auto;
    }

    @include lg {
      padding: rem(24);
      width: rem(740);
      margin: rem(40) auto;
    }

    @include xl {
      padding: rem(32);
      width: rem(1032);
      margin: rem(48) auto;
    }

    .form {
      .field {
        width: rem(288);
        margin-bottom: rem(16);
        
        @include sm {
          width: rem(544);
        }
    
        @include md {
          width: rem(432);
        }
    
        @include lg {
          width: rem(488);
        }
    
        @include xl {
          width: rem(592);
        }

        &.richtext-field,
        &.section-field {
          width: 100%;
        }


        .supporting-label {
          margin-bottom: rem(0);
        }
        &.richtext-field {
          @include sm {
            margin-bottom: rem(24);
          }
        }

        &.section-field {
          margin-bottom: rem(8);
          
          @include sm {
            margin-bottom: rem(16);
          }

          .Heading {
            margin-bottom: 0;
          }
        }

        &.section-field:not(:first-child) {
          margin-top:rem(24);
          margin-bottom:rem(16);
        }

        &.radio-field, &.checkbox-field {
          font-size: rem(14);
          @include sm {
            padding-top: rem(8);
          }

          @include md {
            padding-top: unset;
          }

          .form-label {
            margin-bottom: rem(18);
          }
        }

        .form-control {
          width: 100%;
        }

        .form-label {
          margin-bottom: rem(4);
          font-size: rem(14);
        }

        .other-text-input {
          margin-bottom: 0;
        }
      }

      .Button {
        @include md {
          width: auto;
        }
      }
    }
  }

  .submission-container{
    text-align: center;

    .Heading{
      margin: 0 auto;
    }
    
    .Image{

      height: rem(100);
      width: rem(115);
      margin: rem(8) auto rem(24) auto;

      @include sm{
          height: rem(140);
          width: rem(160);
          margin: 0 auto rem(24) auto;
      }
      @include md{
          margin: rem(8) auto rem(24) auto;
      }
      @include lg{
          margin: rem(8) auto rem(32) auto;
      }
      @include xl{
          margin: rem(16) auto rem(32) auto;
      }
    }

    .Text{
      margin: rem(16) 0;

      @include md{
        margin: rem(16) rem(40);
      }
      @include xl{
        margin: rem(16) rem(144);
      }
    }

    .Button{
      margin: auto;
      height: rem(48);
      width: rem(208);
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: rem(16);

      @include sm{
        margin-bottom: 0;
      }
      @include md{
        margin-bottom: rem(8);
      }
      @include xl{
        margin-bottom: rem(16);
      }
    }
  }
}