@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.LocationCaptureBlock {
  margin: rem(35) rem(8) 0;
  padding: rem(16);

  @include md {
    margin: rem(69) rem(56) 0;
    padding: rem(16);
  }

  @include lg {
    margin: rem(32);
    padding: rem(24);
  }

  @include xl {
    margin: rem(40) rem(100) 0;
    padding: rem(32);
  }

  > .title {
    margin-bottom: rem(16);
  }
}