@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/variables";
@import "src/res/scss/mixins/breakpoints";

.we.navbar {
    background-color: $primary;
    padding: 0;
    height: rem($top-nav-height);
    z-index: 100;
    @include lg {
        height: rem($top-nav-height-lg);
    }

    .navbar-brand {
        padding: 0;
        margin: 0;
        font-size: rem(16);
        #weschools-logo {
            padding: 0 rem(12);
            height: rem(24);
        }
    }
    .navbar-toggler {
        padding: 0 rem(13);
        height: 100%;
        @include lg {
            display: none;
        }
        .navbar-toggler-icon {
            background-image: url("./res/icons/hamburger.svg");
            width: rem(18);
            height: rem(12);
        }
    }
    .nav-slot {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .sign-in {
        padding: rem(8) rem(16);
        margin-right: rem(8);
    }
}
