@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.FiltersBlock {
  .row {
    margin: 0 rem(-8);

    [class^='col-'], [class*=' col-'] {
      padding: rem(8) !important;
    }
  }

  .FilterTile{
    &:nth-of-type(3n+1) {
      padding-left: 0;
    }

    .tile-cta {
      * {
        text-decoration: inherit;
      }

      &:focus {
        text-decoration: underline;
      }

      .tile-body {
        background: $navy-high;
        width: rem(304);
        height: rem(121);

        @include sm {  
          height: rem(223);
          width: rem(560);
        }
        
        @include md {
          height: rem(83);
          width: rem(208);
        }
        
        @include lg {
          height: rem(94.7);
          width: rem(237);
        }
        
        @include xl {
          height: rem(131);
          width: rem(328);
        }

        .tile-content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .tile-title {
            margin-bottom: 0;
            font-size: rem(16);

            @include lg {
              font-size: rem(20);
              line-height: rem(32);
            }
          }
        }
      }
    }
  }

  .expand-button {
    display: flex;
    justify-content: center;
    padding: rem(8) 0;

    button {
      line-height: rem(24);
    }

    .expand-icon {
      height: rem(24);
      width: rem(24);
    }
  }

}