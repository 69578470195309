@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.Feed{
    max-width:rem(304);
    margin:auto;
    @include xs-sm{
        max-width:rem(389);
    }
    @include sm{
        max-width:rem(559);
    }
    @include md{
        max-width:rem(656);
    }
    @include lg{
        max-width:rem(744);
    }
    @include xl{
        max-width:rem(1032);
    }
}