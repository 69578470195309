@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Block{
    margin: rem(16) 0;
    @include lg {
        margin: rem(32) 0;
    }

    .container-fluid{
        padding:0;
    }
    .row{

        margin:0;

        //Removes padding added by bootstrap on the outter columns
        [class^='col-']:first-of-type, [class*=' col-']:first-of-type{
            padding-left:0;
        }
        [class^='col-']:last-of-type, [class*=' col-']:last-of-type{
            padding-right:0;
        }
        @include lt-md{
            [class^='col-'], [class*=' col-']{
                padding:0;
            }
        }

    }
}