@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/variables";
@import "src/res/scss/mixins/breakpoints";

#side_nav {
    width: rem($side-nav-width);
    height: 100vh;
    position: fixed;
    padding-top: rem($top-nav-height);
    background-color: $white;
    top: 0;
    display: none;
    z-index: 99;

    //Always show nav on desktop
    @include lg {
        display: inline-block;
        transform: translateX(0) !important;
        padding-top: rem($top-nav-height-lg);
    }

    //Sliding animation
    &.side-nav-enter {
        display: inline-block;
        transform: translateX(-100%);
    }

    &.side-nav-enter.side-nav-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease-in-out;
    }

    &.side-nav-enter-done {
        transform: translateX(0);
        display: inline-block;
    }

    &.side-nav-exit {
        transform: translateX(0);
        display: inline-block;
    }

    &.side-nav-exit.side-nav-exit-active {
        transform: translateX(-100%);
        transition: transform 300ms ease-in-out;
    }

    .nav-slot {
        &.top {
            position: fixed;
            width: 100%;
        }
        &.bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            padding-bottom: rem(16);
        }
    }
}
