@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";

.we.subheader {
  background-color: $white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  margin-top: 48px;
  padding: 8px;

  @include md {
    padding-left: 16px;
  }

  @include lg {
    margin-top: 56px;
  }

  .exit {
    margin-top: 8px;
    margin-left: 8px;
    line-height:20px;
    vertical-align: bottom;

    @include md {
      margin-left: 16px;
    }
  }

  .title {
    font-weight: bold;
    line-height: 24px;
    margin-left: 8px;
    margin-bottom: 8px;

    @include md {
      margin-left: 16px;
    }
  }
}