@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.SocialMediaBlock .row{
    background-color: $white;
    .social-button{
        height: rem(48);
        width: rem(48);
        padding-left: 0px;
        padding-right: 0px;
        margin-right:rem(16);
        img{
            height:rem(16);
    
        }
    }
    .we.Heading{
        // margin-top:rem(16);
        @include md{
            margin-top:rem(16);
           
        }
        @include lg{
            margin-top:rem(24);
        }
        @include xl{
            margin-top:rem(32);
            margin-bottom:rem(16);
        }
    }
     .image-container{
        height: rem(208);
        @include xs-sm{
            height: rem(259);
        }
        @include sm{
            height: rem(373);
        }
        @include md{
            height:rem(200);
        }
        @include lg{
            height:rem(225);
        }
        @include xl{
            height:rem(312);
        }
    }
    .content-container{
        &[class*=' col-']{
            @include lt-md{
                padding:rem(16);
            }
            @include md{
                padding-right:rem(16);
            }
            @include xl{
                padding-right:rem(32);
            }
        }
    }
    .button-container{
        margin-top:rem(16);
        @include lg{
            margin-top:rem(24);
        }
    }
}