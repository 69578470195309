@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.SearchButton {
    padding: 0;
    img.icon {
        height: rem(24);
        width: rem(24);
        margin: rem(11);
        @include lg {
            margin: rem(7);
        }
    }
}
