@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.ClassroomResourceListBlock{
    .row{
        display: inline;
    }
    .we.Image{
        height:rem(272);
        @include xs-sm{
            height:rem(370);
        }
        @include sm{
            height: rem(528);
        }
        @include md{
            width: rem(188);
            height: rem(188);
        }
    }
    .inner-content{
        margin-top:rem(16);

        @include md{
            display: inline-block;
            vertical-align: top;
            width: rem(420);
            margin-left: rem(16);
            margin-top:0;
        }
        @include lg{
            width: rem(480);
        }
        @include xl{
            width: rem(666);
        }
        
        .we.Text.recommendedFor {
            margin-top: rem(4);
            font-weight: bold;

            .age {
                display: inline;
                font-weight: 100;
                padding-left: rem(6);
            }
        }
        .bodyText {
            color: $navy-low;
            line-height: 1.71;
        }
        .cta-button {
            width:100%;
            vertical-align: top;
            &:not(:last-child){
                margin-bottom: rem(16);
            }
            @include md{
                width: rem(202);
                &:not(:last-child){
                    margin-right: rem(16);
                    margin-bottom: 0;
                }
            }
        }
        .rating-widget {
            margin-bottom: rem(16);
        }
    }
}