/*
* File: Utils
* A collection of helper functions
*/

@import "./variables";

//Converts pixels to rem. Uses browsers default font-size defined in variables
@function rem($pixels) {
    @return #{$pixels/$base-font-size}rem;
}
