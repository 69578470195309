@import "src/res/scss/colors";

.we.Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .Checkbox__Indicator {
    height: 14px;
    width: 14px;
    border-radius: 0.1875rem;
    display: flex;
    border: 1px solid $navy-high;
    margin-right: 8px;
  }
  
  .Selected {
    justify-content: center;
    align-items: center;
    background-color: $navy-high;

    &:after {
        content: "";
        display: block;
        width: 6px;
        height: 10px;
        margin-bottom: 2px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
  }

  .CheckboxLabel {
    margin: 0;
  }
}
