@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Text{
    font-family: Soleil;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;

    //Themes
    &.light{
        color: $navy-high;
    }
    &.dark{
        color: $white;
    }

    //Variants
    &.body{
        font-size: rem(14);
        line-height: rem(24);
        
        @include lg{
            font-size: rem(16);
        }
    }
    &.overline{
        font-size: rem(12);
        line-height: rem(20);
        margin:0;
        @include lg{
            font-size: rem(14);
        }
    }
 


}