@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.MonthlyFeatureBlock{
    .we.Image > span{
        padding:rem(32) rem(16);
        min-height: rem(405);
        justify-content: flex-end;
        flex-direction: column;
        @include xs-sm{
            max-height:rem(155);
        }
        @include md{
            min-height: rem(262);
            padding:rem(32);
        }
        @include lg{
            min-height: rem(279);
        }
        @include xl{
            min-height: rem(412);
            padding:rem(48);
        }
        
    }
    .we.Text {
        @include md{
            max-width: rem(337);
        }
        @include lg{
            max-width: rem(484);
        }
    }
}