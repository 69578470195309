@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.NewestResourcesListBlock{
  .row {
    margin: 0 rem(-8);

    [class^='col-'], [class*=' col-'] {
      padding: rem(8) !important;
    }
  }

  .ResourceTile {
    text-align: left;
    text-transform: none;

    &:active,
    &:hover {
      text-decoration: none;
    }
    
    &:nth-of-type(3n+1) {
      padding-left: 0;
    }

    .tile-content {
      position: relative;
      padding: 0;

      .we.Image {
        background: $navy-high;
        width: rem(304);
        height: rem(304);

        @include sm {  
          height: rem(560);
          width: rem(560);
        }
        
        @include md {
          height: rem(208);
          width: rem(208);
        }
        
        @include lg {
          height: rem(237);
          width: rem(237);
        }
        
        @include xl {
          height: rem(328);
          width: rem(328);
        }
      }

      .resource-title {
        padding: rem(16);

        @include lg {
          font-size: rem(20);
          letter-spacing: rem(0.21);
        }
      }

      .we.Tag{
        margin-top: rem(16)
      }
    }
  }
}