@import "src/res/scss/utils";
@import "src/res/scss/colors";
@import "src/res/scss/mixins/breakpoints";
.we.Input {
    font-family: Soleil;
    line-height: rem(24);
    font-size: rem(16);
    color: $navy-high;
    margin-bottom: 0;

    .Label {
        margin-bottom: 0;
    }
    
    .Error {
        color: $danger;
    }

    .InputContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 1.5rem;
        border: solid rem(1) $white-medium-lite;
        border-radius: 0;
        .Control {
            border: none;
        }
        .Icon {
            align-self: flex-start;
            height: 1.5rem;
            flex: 0 0 1.5rem;
            margin: auto;
            padding: 0rem 0.375rem;
            fill: #2E3A59;
            &:hover {
                cursor: pointer;
            }
        }
        .Input.default {
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            align-self: stretch;
            flex: 1 1 auto;
        }
        button.Icon {
            border: none;
            background: none;
        }
    }

    .Control {
        background-color: #fff;
        border: solid rem(1) $white-medium-lite;
        border-radius: 0;
        height: rem(40);
        line-height: rem(24);
        font-size: rem(16);
        color: $navy-high;
        width: 100%;

        @include md {
            width: rem(312);
        }

        @include xl {
            width: rem(408);
        }

        &:focus {
            border: solid rem(2) $navy-high;
        }

        &.select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -webkit-border-radius: 0;
            background-image: url("./res/icons/arrow-down.svg");
            background-repeat: no-repeat;
            background-position: right rem(8) center;
            background-size: rem(24) rem(24);
        }

        &.textarea {
            height: unset;
        }
    }

    // Autosuggest styling
    .react-autosuggest__suggestions-list {
        margin: rem(4) 0 0 0;
        padding: rem(8);
        list-style-type: none;
        border: solid 1px $white-low;
        width: 100%;

        @include md {
            width: rem(312);
        }

        @include xl {
            width: rem(408);
        }
    }

    .react-autosuggest__suggestion {
        padding: rem(8);
        color: $navy-high;
        font-size: rem(16);
        cursor: pointer;
    }

    //Checkbox styling

}
